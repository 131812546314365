import create from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { createTrackedSelector } from 'react-tracked'

import produce from 'immer'
import { appItem } from '@/constants/homepage'
import { IDisposition } from '@/typings/tass'
import { isCommon } from '@/helper/common'

type TStore = ReturnType<typeof getStore>
function getStore(set) {
  return {
    download: [] as unknown as appItem[],
    setDownloads: (appItem: appItem[]) => {
      set(
        produce((draft: TStore) => {
          draft.download = appItem
        })
      )
    },
    // 二期功能，暂时前端判断写死
    disposition: {} as IDisposition,
    setDisposition: (dis: IDisposition) => {
      set(
        produce((draft: TStore) => {
          draft.disposition = dis
        })
      )
    },
  }
}

const baseDownloadStore = create(subscribeWithSelector(getStore))

const useDownloadStore = createTrackedSelector(baseDownloadStore)
// TODO: 必须要这样调用：baseCommonStore.getState()
export { useDownloadStore, baseDownloadStore }
